import { Inject, Injectable } from '@angular/core';
import { DjangoService, Routes } from '@core/services/django.service';
import { EnvironmentService } from '@core/services/environment.service';
import { GtagService } from '@core/services/gtag.service';
import { IdleCallbackService } from '@core/services/idle-callback.service';
import { WINDOW } from '@core/services/window.service';
import { WebVitalRecord } from '@generated/openapi/model/webVitalRecord';
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Metric } from 'web-vitals/src/types/base';

@Injectable({
    providedIn: 'root',
})
export class WebVitalsService {
    constructor(
        private environmentService: EnvironmentService,
        private idleCallbackService: IdleCallbackService,
        private gtagService: GtagService,
        private djangoService: DjangoService,
        @Inject(WINDOW) private window: Window
    ) {}

    collectWebVitals() {
        if (
            this.environmentService.isBrowser() &&
            !this.environmentService.isPreview()
        ) {
            this.idleCallbackService
                .idleCallBack()
                .subscribe(() => this.recordWebVitals());
        }
    }

    private recordWebVitals() {
        from(import(/* webpackChunkName: "webVitalsPackage" */ 'web-vitals'))
            .pipe(
                tap(webVitals => {
                    webVitals.onCLS(data => this.sendWebVitals(data));
                    webVitals.onFID(data => this.sendWebVitals(data));
                    webVitals.onLCP(data => this.sendWebVitals(data));
                    webVitals.onTTFB(data => this.sendWebVitals(data));
                    webVitals.onFCP(data => this.sendWebVitals(data));
                    webVitals.onINP(data => this.sendWebVitals(data));
                })
            )
            .subscribe();
    }

    sendWebVitals(metric: Metric) {
        const record: WebVitalRecord = {
            metric: metric.name,
            value: metric.value,
        };
        this.djangoService
            .drfCreate<WebVitalRecord>(Routes.webVitals, record)
            .subscribe();
    }
}
