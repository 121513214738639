import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { LoginRedirectService } from '@core/services/login-redirect.service';
import { UserService } from '@features/user/services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IsAuthenticatedGuard {
    constructor(
        private userService: UserService,
        private router: Router,
        private loginRedirectService: LoginRedirectService
    ) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.userService
            .isAuthenticated()
            .pipe(
                map((isAuthenticated: boolean) =>
                    isAuthenticated
                        ? isAuthenticated
                        : this.loginRedirectService.remember(state)
                )
            );
    }
}
