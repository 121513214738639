import { ErrorHandler, Inject, Injectable, Optional } from '@angular/core';
import { environment } from '@environment/environment';
import { Integration } from '@sentry/types';
import { Observable, defer } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { REQUEST, SSR_SENTRY_INTEGRATIONS } from '../../../express.tokens';
import { EnvironmentService } from '../services/environment.service';
import { WINDOW } from '../services/window.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    private sentryErrorHandler: Observable<ErrorHandler>;

    private sentry: Observable<any>;
    constructor(
        private environmentService: EnvironmentService,
        @Inject(WINDOW) private window: Window,
        @Optional() @Inject(REQUEST) private request: Request,
        @Optional()
        @Inject(SSR_SENTRY_INTEGRATIONS)
        private ssrIntegrations: Integration[]
    ) {
        super();
        this.sentry = defer(
            () =>
                import(
                    /* webpackChunkName: "sentryPackage" */ '@sentry/angular'
                )
        ).pipe(
            tap(sentry =>
                sentry.init({
                    dsn: environment.sentryBrowserDSN,
                    environment: this.environmentService.environment,
                    release: this.environmentService.version,
                    integrations: [
                        ...(this.ssrIntegrations ? this.ssrIntegrations : []),
                    ],
                })
            ),
            shareReplay(1)
        );

        this.sentryErrorHandler = this.sentry.pipe(
            map(sentry => sentry.createErrorHandler()),
            shareReplay(1)
        );
    }

    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            this.window.location.reload();
        } else {
            this.sentry
                .pipe(
                    tap(sentry => {
                        sentry.setContext('runtime', {
                            runtime:
                                this.environmentService.runtimeEnvironment(),
                        });
                        if (
                            this.environmentService.isServer() &&
                            this.request
                        ) {
                            sentry
                                .getIsolationScope()
                                .setSDKProcessingMetadata({
                                    request: this.request,
                                });
                        }
                    }),
                    switchMap(() => this.sentryErrorHandler),
                    take(1)
                )
                .subscribe(errorHandler => errorHandler.handleError(error));
        }
    }
}
