import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    GuardResult,
    MaybeAsync,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { EnvironmentService } from '@core/services/environment.service';

@Injectable({
    providedIn: 'root',
})
export class IsBrowserGuard implements CanActivate {
    constructor(
        private environmentService: EnvironmentService,
        private router: Router
    ) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): MaybeAsync<GuardResult> {
        if (this.environmentService.isBrowser()) {
            return true;
        } else {
            return this.router.parseUrl('/not-authenticated');
        }
    }
}
