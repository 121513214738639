import { AsyncPipe, DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { CookieConsentService } from '@core/services/cookie-consent.service';
import { DeviceService } from '@core/services/device.service';
import { EnvironmentService } from '@core/services/environment.service';
import { EventTrackerService } from '@core/services/event-tracker.service';
import { MetadataService } from '@core/services/metadata.service';
import { SeoService } from '@core/services/seo.service';
import { WINDOW } from '@core/services/window.service';
import { JsonLdComponent } from '@features/seo/json-ld/json-ld.component';
import { WebVitalsService } from '@features/seo/services/web-vitals.service';
import { SeoMetadata } from '@models/seo-metadata.model';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterOutlet, JsonLdComponent, AsyncPipe],
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'referral';
    destroySubject = new Subject();
    seoData: Observable<SeoMetadata>;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        private deviceService: DeviceService,
        private environmentService: EnvironmentService,
        private renderer2: Renderer2,
        private router: Router,
        private seoService: SeoService,
        private metadataService: MetadataService,
        private webVitalsService: WebVitalsService,
        private cookieConsentService: CookieConsentService,
        private eventTrackerService: EventTrackerService
    ) {}

    ngOnInit(): void {
        this.removeNoJsClass();
        this.seoData = this.seoService.seoMetadata$;

        this.disableIndexingForNonProductionEnvironments();
        this.webVitalsService.collectWebVitals();

        this.eventTrackerService.setup();
    }

    removeNoJsClass() {
        if (this.environmentService.isBrowser()) {
            this.renderer2.removeClass(this.document.body, 'no-js');
        }
    }

    ngOnDestroy(): void {
        console.log('destroy');
        this.destroySubject.next(null);
        this.destroySubject.complete();
    }

    private disableIndexingForNonProductionEnvironments() {
        if (!this.environmentService.isProduction()) {
            this.metadataService.setAttribute({
                name: 'robots',
                content: 'noindex, nofollow',
            });
        }
    }
}
