import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Routes } from '@core/services/django.service';
import { EnvironmentService } from '@core/services/environment.service';
import { TokenStorageService } from '@features/user/services/token-storage.service';
import { UserService } from '@features/user/services/user.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private tokenStorageService: TokenStorageService,
        private environmentService: EnvironmentService,
        private router: Router,
        private userService: UserService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.headers.get('skip')) {
            const headersWithoutSkip = request.headers.delete('skip');
            return next
                .handle(request.clone({ headers: headersWithoutSkip }))
                .pipe(catchError(e => this.handleAuthError(e)));
        }

        return this.tokenStorageService.getToken().pipe(
            mergeMap(token => next.handle(this.addToken(request, token))),
            catchError(e => this.handleAuthError(e))
        );
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        //handle your auth error or rethrow
        if (
            (err.url || Routes.user).includes(Routes.user) &&
            (err.status === 401 || err.status === 403)
        ) {
            //navigate /delete cookies or whatever
            console.error('Error Refreshing Token', err.status, err);
            this.userService
                .logout()
                .subscribe(() => this.router.navigate(['/']));

            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(() => err);
    }

    private addToken(request: HttpRequest<any>, token: string) {
        if (token) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'FA-Client-Version': this.environmentService.version,
                },
            });
        } else {
            return request.clone({
                setHeaders: {
                    'FA-Client-Version': this.environmentService.version,
                },
            });
        }
    }
}
