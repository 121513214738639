import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { UserService } from '@features/user/services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IsStaffGuard {
    constructor(
        private userService: UserService,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.userService
            .isStaff()
            .pipe(
                map((isStaff: boolean) =>
                    isStaff ? isStaff : this.router.parseUrl('/dashboard')
                )
            );
    }
}
